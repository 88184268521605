body,
html {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.app-container {
  display: flex;
  height: calc(100vh - 70px);
  height: 100%;
  overflow-y: auto;
}

#tickets-full {
  padding: 20px;
  width: calc(100vw - 80px);
  height: calc(100vh - 40px);
  margin-left: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f6f8fb;
  .tickets-form-container {
    margin: 20px 0;
  }
  .colored-header {
    color: white;
  }
  .info-section {
    & ~ .info-section {
      margin-top: 10px;
      border-top: 1px solid #dddddd;
      padding-top: 10px;
    }
  }
}
