@import '../mixins/_opened-table.scss';

$content-min-width: 1180px;
$map-control-height: 45px;
$google-map-height: 250px;
$search-height: 70px;
$search-badges-height: 30px;
$common-margin-height: 10px;

.observations-map-control {
  height: $map-control-height;
}

#google-map {
  margin-top: $common-margin-height;
}

#observations {
  &.c01 {

    #google-map,
    .observations-map-control~div:not(#google-map) {
      height: $google-map-height;
    }

    .ucr-details-container {
      height: 444px;
    }
  }

  &.scancar {

    #google-map,
    .observations-map-control~div:not(#google-map) {
      height: calc(#{$google-map-height} + 100px);
    }

    .ucr-details-container {
      height: 444px;
    }
  }
}

.ucr-details-container {
  margin-bottom: $common-margin-height;
}

.observations-container {
  .search {
    height: $search-height;
  }

  .badges-container {
    height: $search-badges-height;
  }
}

#observations {
  padding: 10px 10px;
  width: calc(100vw - 80px);
  height: calc(100vh - 40px);
  margin-left: auto;
  overflow: auto;
  background-color: #f6f8fb;
}

.observations-container {
  min-width: $content-min-width;

  .search {
    align-items: center;
    display: flex;

    >div {
      width: calc(100% + 15px);

      >div:nth-child(2) {
        padding: 0;
      }

      >div:nth-child(3),
      >div:nth-child(4) {
        padding-right: 0;
      }
    }

    input,
    select {
      font-size: 0.875rem;
      height: 38px;
      padding: 0.375rem 0.5rem;
    }

    label {
      font-size: 0.875em;
      margin-bottom: 0;
    }

    .form-group {
      margin-bottom: 0;
    }

    .input-group-append .input-group-text {
      padding: 0.375rem 0.5rem;
    }

    .invalid-feedback {
      margin-bottom: -0.25rem;
    }
  }

  .searching-badges {
    .badges-container {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.class-with-margin {
  margin-right: 5px;
}

.class-with-margins {
  margin-top: 20px;
  margin-bottom: 20px;
}

#google-map {
  min-width: $content-min-width;
}

.observations-map-control {
  display: flex;
  min-width: $content-min-width;
  padding: 5px;
  color: black;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left {
    min-width: 50%;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dropdown-toggle {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin-bottom: 0;
      }
    }

    a {
      outline: none;

      button {
        margin-left: 10px;
      }
    }

    >.dropdown {
      margin: 0 10px;
    }

    .dropdown-toggle>img {
      margin: 0 3px 0 0;
    }

    .camera-name {
      color: #ff6f00;
      display: block;
      font-size: 16px;
      margin-left: 10px;

      &:not(:first-of-type) {
        margin-top: 7px;
      }

      &+.dropdown-divider {
        margin-bottom: 0;
        margin-top: 2px;
      }
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    >svg,
    >.whitelist {
      margin: 0 10px;
    }
  }
}

.delete-all {
  float: right;
  margin-bottom: 10px;
}

.observations-container {
  >span {
    display: inline-block;
    padding-top: 0.5em;
  }
}

.observation-table {
  background-color: white;
  margin-bottom: 10px;
  width: 100%;

  thead {
    th {
      height: 34px;
    }
  }

  tbody {
    font-size: 0.875em;

    td {
      height: 32px;
    }
  }

  .obs-row-selected {
    border: 3px solid #ff6f00;
    color: #ff6f00;
    font-size: 0.95em;
    font-weight: bold;
  }

  .obs-row-delete {
    >td {
      background-color: #b2b7bc;
    }
  }

  .obs-row-warning {
    >td {
      background-color: #faf2cc;
    }
  }

  .obs-row-ticket {
    >td {
      background-color: #f2dede;
    }
  }

  .obs-row-assigned {
    >td {
      background-color: #eceeef;
    }

    .assigned-icon {
      color: #e3c221;
    }
  }

  .obs-row-exemption {
    >td {
      background-color: #d1e4fc;
    }
  }

  .obs-row-needs-follow-up {
    >td {
      background-color: #e4fcd1;
    }
  }
}

.observation-table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  >ul {
    margin: auto;
  }
}
