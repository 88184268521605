@mixin opened-table-borders {
  .details-container {
    padding: 0;
    min-height: 490px;
  }
  tbody {
    .open:first-child {
      border-top: 3px solid #ff6f00;
    }
  }
  tr.open {
    color: #ff6f00;
    > td {
      border-top: 1px solid #ff6f00;
    }
    td:last-child {
      border-right: 1px solid #ff6f00;
    }
    td:first-child {
      border-left: 1px solid #ff6f00;
      i:first-child {
        transform: rotate(90deg);
      }
    }
    & + tr {
      > td {
        > div {
          border: 1px solid #ff6f00;
          border-top: none;
        }
      }
    }
  }
  i {
    display: inline-block;
    cursor: pointer;
    transition: 0.4s transform ease;
  }
}
