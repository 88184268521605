@import '../mixins/_opened-table.scss';

#tickets {
  padding: 10px 20px;
  width: calc(100vw - 80px);
  height: calc(100vh - 40px);
  margin-left: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f6f8fb;
  .tickets-table {
    background-color: #fff;
    @include opened-table-borders;
    th i {
      color: #979797;
    }
    .colored-header {
      color: white;
    }
    .form-holder {
      padding: 20px;
      .btn-holder {
        margin-top: 20px;
        text-align: right;
        width: 100%;
        > button {
          margin-left: 10px;
        }
      }
    }

    .tag {
      min-width: 88px;
    }
  }
  .tickets-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .pagination {
      margin: auto;
    }
  }
}

#tickets-search {
  .current-filters {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .my-modal-lg {
    width: auto;
  }
}