#left-menu {
  height: calc(100vh - 40px);
  background-color: #fff;
  width: 80px;
  border-right: 1px solid #a9a9a9;
  padding: 20px;
  > section:first-child {
    margin-bottom: 20px;
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    color: #666;
    font-size: 14px;
    font-weight: medium;
    &:hover {
      color: #ff6f00;
    }
    > img {
      height: 65px;
    }
  }

  .cop-menu a > img {
    padding: 13px;
  }
}
