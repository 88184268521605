@import '../mixins/_images-bg.scss';

.observation-search {
  .row {
    align-items: center;
  }
}

.observation-details {
  background-color: #fff;
  border: 2px solid #ff6f00;
  display: flex;
  height: 100%;
  .full-height {
    height: 100%;
  }
  .list-container {
    height: 72%;
    overflow-y: scroll;
    .nav-item a {
      padding: 8px 10px;
    }
  }
  .observation-actions {
    height: 12%;
    display: flex;
    align-items: center;
    border-top: 1px solid #eceeef;
    button {
      width: 100%;
    }
    .row {
      width: 100%;
      > div {
        padding: 5px;
      }
    }
  }
  .right-container {
    height: 100%;
    padding: 5px 10px;
  }
  .repeat-alerts {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    padding: 5px 0;
    > div {
      flex: 1;
      height: 100%;
      padding: 0.2rem 0.5rem;
    }
    div:first-child {
      margin-right: 5px;
    }
  }
  > .row {
    width: 100%;
  }
  i {
    cursor: pointer;
  }
}

.observation-relation-control {
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button,
  a {
    flex: 1;
    min-width: 120px;
    max-width: 190px;
    margin: 0 3px;
  }
  button:last-child {
    margin-right: 0;
  }
}

.related-observations-list {
  height: calc(100% - 105px);
  > ul {
    height: 98%;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    border-bottom: 1px solid #eceeef;
    &:hover {
      overflow-y: auto;
    }
  }
}

.related-observation-listitem {
  display: flex;

  .related-observation-listitem-icon {
    flex: 1;
  }
  .related-observation-listitem-text-short {
    flex: 2;
  }
  .related-observation-listitem-text-long {
    flex: 5;
    white-space: nowrap;
  }
  span {
    font-size: 0.825rem;
  }
  .text-centered {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
}

.observation-images {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1px;

  .observation-image {
    @include images-container;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
  }
}

.observation-panorama-container {
  background-color: #eceeef;
  border-right: 1px solid #fff;
  height: 100px;

  p {
    color: #fd6e1d;
    line-height: 30px;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  .observation-panorama-images {
    height: 70px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 10px 10px;
    white-space: nowrap;

    .observation-image {
      @include images-container;
      display: inline-block;
      height: 100%;
      margin-right: 10px;
      width: 60px;
    }
  }
}

.offense-card {
  height: 100%;

  .offense-card-exemption-container {
    text-align: right;
  }
  .offense-read-only-fields {
    > div {
      display: flex;
    }
    span {
      flex: 1;
      font-size: 0.75rem;
      margin-bottom: 5px;
      &:last-child {
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
}

.observation-form {
  form {
    border-bottom: 1px solid #ff6f00;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .license-plate-input {
    text-transform: uppercase;
  }
  .observation-form-license-plate {
    font-size: 1.125em;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin: 10px 0;
    white-space: nowrap;

    > div {
      display: inline-block;

      &:first-child {
        background-color: #31448b;
        border-radius: 5px 0 0 5px;
        color: #fff;
        text-align: center;
        width: 55px;
      }
      &:last-child {
        text-align: center;
        border-radius: 0 5px 5px 0;
        letter-spacing: 1px;
        max-width: 120px;
        width: calc(100% - 55px);
        box-shadow: inset 0px 0px 0px 3px #fd6e1d;
      }
    }
  }
  .observation-form-offenses {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    padding-left: 0;

    .repeat-offender-button {
      flex: 50%;
      margin-left: 5px;
      max-width: 140px;

      button {
        border-radius: 5px;
        color: #212529;
        height: 40px;
        padding: 0 5px;
        width: 100%;

        &.btn-danger {
          background-color: #efdfde;
        }
        &.btn-warning {
          background-color: #f9f1d0;
        }
      }
    }
  }
  .form-label {
    font-size: 0.825em;
    line-height: 1;
  }
  .form-group {
    margin-bottom: 5px;
  }
  .col-with-info {
    display: flex;
    align-items: center;
    .info-icon {
      align-self: center;
      margin-top: 10px;
    }
  }
  .offense-code {
    display: flex;
    flex-wrap: wrap;
    &.offense-code-alert {
      input {
        background-color: #ff6f00;
      }
    }
    label {
      width: 100%;
    }
    .dropdown {
      margin-right: 5px;
      width: 50%;
    }
    .form-text {
      margin-top: 5px;
    }
  }
  .custom-switch {
    padding-right: 5px;
    text-align: right;

    label {
      font-size: 0.875em;
      padding-top: 2px;
      vertical-align: middle;
    }
  }
  .general-actions {
    margin-top: 5px;
  }
}

.observation-process-summary {
  .card-header {
    > span {
      cursor: pointer;
    }
  }
  .summary-footer {
    flex-wrap: nowrap !important;
    > button {
      min-width: 120px;
    }
    > button:first-child {
      margin-right: auto;
    }
  }
}

.prp-badge {
  cursor: pointer;
  margin-bottom: 0 !important;
}
.prp-modal {
  button {
    margin-left: auto;
    width: fit-content;
  }
}

.observation-relation-header {
  display: flex;
  flex-direction: column;
  height: 16%;
  padding: 5px;
  .observation-relation-header-title {
    display: flex;
    align-items: center;
  }
  h5 {
    color: #ff6f00;
    display: inline-block;
    font-weight: bold;
    margin: 0.25rem;
  }
  .info-label {
    margin-left: 0.25em;
    p {
      margin: 0 5px;
    }
  }
}

.reset-observation-icon {
  color: #232f3e;
  &:hover {
    color: darken(#232f3e, 25%);
  }
}

.clickable {
  cursor: pointer;
}

.observation-exempted-modal {
  .exempted-modal-body {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    .exemption-item {
      border-bottom: 1px solid #eceeef;
      margin-bottom: 10px;
    }
  }
}
