body,
html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.app-container {
  display: flex;
  height: calc(100vh - 40px);
  overflow-y: auto;

  > div {
    padding: 10px 20px;
    width: calc(100vw - 125px);
    height: calc(100vh - 70px);
    margin-left: auto;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f6f8fb;
  }
}

.maximum-sized-dropdown {
  max-height: 60vh;
  overflow-y: auto;
}

.invalid-feedback {
  display: block;
}

@for $i from 1 through 10 {
  .offset-bottom-#{$i} {
    margin-bottom: #{$i}px;
  }
}

.zoom-modal {
  margin: auto;
  padding: 0 !important;
  .modal-dialog {
    margin: auto !important;
    max-width: 1500px;
    padding: 20px;
  }
  .modal-body {
    padding: 0;
  }
  .carousel__slider,
  .carousel__inner-slide {
    height: calc(100vh - 190px);
  }
  .carousel__image {
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain !important;
  }
  .zoom-modal-controls {
    display: flex;
    height: 150px;
    .carousel__back-button,
    .carousel__next-button {
      background-color: #e9ecef;
      border: 0;
      border-radius: 0.3rem;
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 0;
      margin: 25px;
      width: 50px;
    }
    .carousel__dots {
      display: flex;
      justify-content: center;
      padding: 10px;
      width: calc(100% - 200px);
      .carousel__dot {
        background-color: #fff;
        border: 4px solid #fff;
        flex: 1;
        margin: 0 10px;
        padding: 0;
        max-width: 160px;
        &.carousel__dot--selected {
          border: 4px solid #f00;
        }
      }
      .carousel__image {
        height: auto;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        width: auto;
      }
    }
  }
  .zoom-modal-close {
    color: white;
    cursor: pointer;
    font-size: 2.5rem;
    padding: 0 15px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }
}

.icon-danger {
  color: #dc3545;
  * {
    color: #dc3545;
  }
}

.icon-dark {
  color: #232f3e;
  * {
    color: #232f3e;
  }
}

.icon-green {
  color: #28a745;
  * {
    color: #28a745;
  }
}

.icon-white {
  color: white;
  * {
    color: white;
  }
}

.small-footer {
  position: absolute;
  top: 702px;
  left: 50%;
  margin: auto;
  transform: translate(-50%, 0);
}
