.occupancy-dashboard {
  max-height: 70vh;
  min-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  .occupancy-item {
    &:last-of-type {
      margin-bottom: 250px;
    }
    margin: 10px;
    .info {
      border: 1px solid #22303e;
      border-radius: 2px;
      .header-container {
        border-right: 1px solid #22303e;
        padding: 20px;
      }
      .occupancy-labels {
        padding: 0;
        display: flex;
        flex-direction: column;
        main {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px;
        }
        footer {
          display: flex;
          justify-content: flex-end;
          background-color: #f6f8fb;
        }
      }
    }
    .occupancy-card-container {
      position: relative;
      .correction-card {
        position: absolute !important;
        top: 0;
        left: 20px;
        z-index: 99;
        min-width: 400px;

        .capacity-header {
          display: flex;
          small {
            margin-right: 5px;
          }
          justify-content: flex-end;
          align-items: baseline;
        }

        .correction-card-footer {
          display: flex;
          padding: 10px;
          button {
            margin: 5px;
          }
          justify-content: flex-end;
        }
      }
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      > svg {
        transition: all ease 0.6s;
        transform: rotate(-180deg);
      }
    }
    i.opened-occupancy {
      color: #ff6f00;
      > svg {
        transform-origin: center;
        transform: rotate(0);
      }
    }
  }
  .report-info-container {
    margin: 30px 0 0 10px;
    .title {
      font-weight: bold;
    }
    .status-header {
      margin-left: 30px;
    }
    .status {
      color: white;
      border-radius: 5px;
      padding: 2px 5px;
      font-weight: bold;
      margin: 0 10px 0 5px;
      &.error {
        background-color: red;
      }
      &.ok {
        background-color: green;
      }
    }
  }
  .action-buttons {
    margin-top: 10px;
  }
}
.scheduled-override-table-container {
  hr {
    border-top: 2px solid rgba(0,0,0,0.1);
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
.schedule-form-btn-holder {
  display: flex;
  justify-content: flex-end;
  button {
    margin: 5px;
  }
}
.weekdays-error-hint {
  &.is-invalid {
    display: block;
  }
}
.part-of-day-container {
  label {
    text-align: right;
  }
}
.edit-correction {
  margin-left: 10px;
  color: #ff6f00;
}
.occupancy-slider {
  width: 100% !important;
  margin: 10px 0 15px 0;
}
