@import '../mixins/_images-bg.scss';
@import '../shared/_fonts.scss';

#tickets-search {
  .search-btn {
    align-self: center;
    margin-top: 12px;
  }
}

#officer-statement-search-icon {
  margin-left: 10px;
  color: black;
}

#officer-statement-search {
  button {
    margin-right: 5px;
  }
}

.officer-statement-placeholder {
  border-radius: 5px;
  border: 1px dotted #464633;
  background-color: #efefaf;
  min-width: 80px;
  min-height: 21px;
  display: inline-block;
  vertical-align: middle;
}

#offense-clarification-search {
  .search-result-card-list {
    height: 25vh;
    overflow-y: auto;
  }
}

$image-size: 400px;

.attachments-holder {
  padding: 20px;
  padding-bottom: 40px;
  .attachment-image {
    @include images-container;
    width: 100%;
    min-height: $image-size;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.attachments-footer {
  text-align: right;
  > input[type='file'] {
    display: none;
    opacity: 0;
  }
}

.attachments-file-list {
  list-style: none;
  > li {
    border-bottom: 1px solid #979797;
    margin: 10px 20px 5px 5px;
    padding: 5px;
  }
}

.remarks-content {
  overflow-y: hidden;
  padding: 20px;
  width: 100%;
  ul {
    width: 100%;
    list-style: none;
    max-height: 40vh;
    overflow-y: auto;
  }
}

.html-result {
  span {
    background-color: yellow;
  }
}

.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.history-search-row {
  border-left: 1px solid rgb(203, 68, 74);
  border-right: 1px solid rgb(203, 68, 74);
}

.history-tag {
  background-color: rgba(203, 68, 74, 0.26);
  color: rgb(203, 68, 74);
}

.history-search-description {
  color: rgb(203, 68, 74);
}

.filter-align {
  .toggle-switch-div {
    display: inline-flex;
    margin-right: 10px;
  }
}
